import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCode, Languages, TimeZone } from 'src/app/enums/language-codes';
import { LocalStorageItem } from 'src/app/enums/localStorage-item';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit{
  languages: { name: string; code: string[]; flag: string; }[];
  selectedLang: { name: string; code: string[]; flag: string; } | undefined;
  defaultLang: string | undefined;
  formats: { locale: string[]; dateFormat: string; }[];
  selectedFormat: { locale: string[]; dateFormat: string; } | undefined;
  settingsVisible: boolean = false;

  constructor(private translate: TranslateService, private sharedService: SharedService) {}

  ngOnInit() {
    this.languages = [
      { name: Languages.Italian, code: [LanguageCode.Italian, 'it'], flag: 'assets/images/flags/Italy.png' },
      { name: Languages.EnglishUS, code: [LanguageCode.EnglishUS, 'en'], flag: 'assets/images/flags/USA.png' },
      { name: Languages.EnglishGB, code: [LanguageCode.EnglishGB, 'en'], flag: 'assets/images/flags/UK.png' },
    ];

    this.formats = [
      { locale: [LanguageCode.Italian, LanguageCode.EnglishGB], dateFormat: 'dd/MM/yyyy'},
      { locale: [LanguageCode.EnglishUS], dateFormat: 'MM/dd/yyyy'},
      { locale: [LanguageCode.English], dateFormat: 'yyyy-MM-dd'},
    ];

    const userLang = localStorage.getItem(LocalStorageItem.PreferredLanguage);
    if (userLang && this.sharedService.isLanguageSupported(userLang)) {
      this.selectedLang = this.languages.find(
        lang => lang.code.includes(userLang));
      this.translate.use(userLang);
    }

    const userDateFormat = localStorage.getItem(LocalStorageItem.PreferredDateFormat);
    if (userDateFormat) { //&& this.sharedService.isFormatSupported(userLang)
      const parsedFormat = JSON.parse(userDateFormat);
      this.selectedFormat = this.formats.find(
        format => format.dateFormat === parsedFormat.dateFormat);
    }
  }

  onLanguageChange(language: any): void {
    setTimeout(() => {
      this.selectedLang = language;
    });
  }

  onFormatChange(format: any): void {
    setTimeout(() => {
      this.selectedFormat = format;
    });
  }

  applySettings(): void {
    if (this.selectedLang) {
      this.translate.use(this.selectedLang.code[0]);
      localStorage.setItem(LocalStorageItem.PreferredLanguage, this.selectedLang.code[0]);
    }

    if (this.selectedFormat) {
      const preferredFormat = {
        locale: this.selectedFormat.locale,
        dateFormat: this.selectedFormat.dateFormat
      };

      localStorage.setItem(LocalStorageItem.PreferredDateFormat, JSON.stringify(preferredFormat));
    }

    this.settingsVisible = false;

    window.location.reload();
  }

  openSettings(): void {
    this.settingsVisible = true;
  }
}

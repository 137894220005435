import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(@Inject(LOCALE_ID) private locale: string, private translate: TranslateService) { }

  getLocaleDateFormat(dateString: string): string {
    const date = new Date(dateString);

    // Check if the date is valid
    if (!isNaN(date.getTime())) {
      // Format the given date based on the user's locale
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
      return new Intl.DateTimeFormat(this.locale, options).format(date);
    } else {
      // Handle invalid date
      return 'Invalid Date';
    }
  }

  getLocaleDateTimeFormat(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);

    if (!isNaN(dateTime.getTime())) {
      // Format the given date and time based on the user's locale
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };

      return new Intl.DateTimeFormat(this.locale, options).format(dateTime);
    } else {
      return 'Invalid Date';
    }
  }

  formatAmount(amount: number, currency: string, includeCurrency = true): string {
    const isNegative = amount < 0;
    const absoluteAmount = Math.abs(amount);

    const formattedAmount = new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    }).format(absoluteAmount);

    const formattedValue = isNegative
      ? `( ${formattedAmount} )`
      : formattedAmount;

    return includeCurrency ? formattedValue + ' ' + currency : formattedValue;
  }

  formatIdByGroup(id: string): string {
    const formattedId = id.replace(/(\S{4})/g, '$1 ');

    return formattedId.trim();
  }

  isLanguageSupported(language: string): boolean {
    const supportedLangs = this.translate.getLangs();
    return supportedLangs.includes(language);
  }
}

<style>
  .logo {
    display: block;
    text-align: center; /* Center the logo horizontally */
    padding: 1rem; /* Add some padding for spacing */
    margin-top: 2rem;
  }

  .logo img {
    width: 166.467px; /* Adjust the width as needed */
    height: 50px; /* Maintain aspect ratio */
    flex-shrink: 0;
  }
</style>
<!-- <div *ngIf="isLoading" class="card flex justify-content-center">
  <p-progressSpinner strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div> -->
<ul class="layout-menu">
  <div class="logo">
    <img src="../../assets/layout/images/logo.png" alt="Logo">
  </div>
  <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>
</ul>

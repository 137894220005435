import { Injectable } from '@angular/core';
import { HttpService } from '../services/http-service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsSErvice {

  constructor(private httpService: HttpService) { }

  changePassword(userId : string ,data : any) : Observable<any>{
    return this.httpService.post(`/Auth/ChangePassword?userId=${userId}`,data);
  }

}

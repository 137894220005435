import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'hermes-client';
  constructor(private primengConfig: PrimeNGConfig, private translate: TranslateService, private sharedService: SharedService) {
    this.translate.addLangs(['it-IT', 'en-US', 'en-GB', 'en']);
   }

  ngOnInit() {
    JSON.stringify(localStorage);
    this.primengConfig.ripple = true;
    const browserLang = navigator.language;

    const userLang = localStorage.getItem('preferredLanguage');
    const defaultLang = 'it-IT';

    this.sharedService.ensureValidCookie();
    if (userLang == null) {
      localStorage.setItem('preferredLanguage', this.sharedService.isLanguageSupported(browserLang) ? browserLang : 'it-IT');
    }
    this.translate.use(userLang || defaultLang);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root',
})

export class HttpService {
    hermesApiUrl = environment.hermesApiUrl;

    headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    });
    version  = "/v1";
    httpOptions = { headers: this.headers }

    constructor(private http: HttpClient) {
        this.httpOptions = { headers: this.headers };

    }
    get(serviceName: string) {
        let url = this.hermesApiUrl + this.version  + serviceName;

        return this.http.get(url, this.httpOptions);
    }

    getAdditionalHeaders(serviceName: string, additionalHeaders: Map<string, string>) {
        let headers = this.httpOptions.headers;

        for (const [key, value] of additionalHeaders) {
            headers = headers.set(key, value);
        }

        this.httpOptions.headers = headers;
        const url = this.hermesApiUrl + this.version + serviceName;

        return this.http.get(url, this.httpOptions);
    }

    post(serviceName: string, data: any): Observable<any> {
        let url = this.hermesApiUrl + this.version + serviceName;
        const res = this.http.post(url, data, this.httpOptions);
        return res;
    }
}

import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CardActivation } from 'src/app/models/cambrist/card-activation';
import { PaymentCardService } from 'src/app/services/cambrist/payment-card.service';
import { Message, MessageService } from 'primeng/api';
import { CardActions } from 'src/app/enums/card-actions';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/api-response';
import { GroupActivationExecution } from 'src/app/models/hermes/group-activation-execution';


registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-group-activation',
  templateUrl: './group-activation.component.html',
  styleUrls: ['./group-activation.component.scss']
})
export class GroupActivationComponent {
  firstExternalCardNumber: string;
  lastExternalCardNumber: string;
  lowSeqNumber: number;
  highSeqNumber: number;
  loadAmount: number;
  activating: boolean;
  msgs: Message[] = [];
  cardCount: number;
  apiErrorMessage: string;
  totalLoadAmount: number;
  clientNote: string;
  totalCardCount: number;


  activationForm = new FormGroup({
    firstExternalCardNumber: new FormControl('', Validators.required),
    lastExternalCardNumber: new FormControl('', Validators.required),
    lowSeqNumber: new FormControl('', Validators.required),
    highSeqNumber: new FormControl('', Validators.required),
    numberOfCards: new FormControl('', Validators.required),
    loadAmount: new FormControl('', Validators.required),
    totalLoadAmount: new FormControl({value: 0.00, disabled: true}, Validators.required),
    clientNote : new FormControl('', Validators.required),
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private card: PaymentCardService, private service: MessageService, private formBuilder: FormBuilder) {

  }

  async activateCards(): Promise<void> {
    let startFrom = new Date().getTime();
    this.activating = true;

    this.card.getCardIds(this.cardExternalRefs()).subscribe((response: any) => {
      if (response && response.isSuccess) {
        var firstCardId: number = response.value[0].id;
        var lastCardId: number = response.value[1].id;
        var cardActivationData = this.getCardActivationData(firstCardId, lastCardId);
        this.card.prepareCardAction(cardActivationData).subscribe((response: any) => {
          if (!response && response.isSuccess) {
            // splitting the error message from api.
            var error = response.message;
            this.apiErrorMessage = error;
            // assign the neccassary error details.
            //this.apiErrorMessage = split[1].toUpperCase();
            // close the loader spinner.
            this.activating = false;
            return;
          }


          var activationExecution = {
            actionId: response.value.id,
            totalCardCount: (this.highSeqNumber - this.lowSeqNumber) + 1,
            amountPerCard: this.loadAmount,
            sequenceNumberHigh: this.highSeqNumber,
            sequenceNumberLow: this.lowSeqNumber,
            clientNote: this.clientNote
          }

          // executing group activation
          // passing the prepare action id to call the execute endpoint.
          this.card.executeCardAction(response.value.id, activationExecution).subscribe((response: any) => {


            this.service.add({ key: 'tst', severity: 'success', summary: 'Processing activation!', detail: 'Execution ID: ' + response.value.id + '.' + ' Status: ' + response.value.status });
            // set the activating to false to disable / hide the loader
            this.activating = false;
            // close the group activation dialog box.
            this.ref.close();
          });
        }, (apiError) => {
          // will add http error interceptor in the future.
          this.activating = false;
          console.log(apiError);
          if(apiError.status === 404) {
            this.apiErrorMessage = apiError.error.message;
          }
          else {
            this.apiErrorMessage = apiError.error;
          }
        });
      }
      else {
        this.apiErrorMessage = response.error.message;
        this.activating = false;
      }
    });
  }

  cardExternalRefs(): Array<string> {
    var cardExtRefs = new Array<string>();

    cardExtRefs.push(this.firstExternalCardNumber);
    cardExtRefs.push(this.lastExternalCardNumber)

    return cardExtRefs
  }

  getCardActivationData(firstCardId: number, lastCardId: number): CardActivation {
    let cardActivation!: CardActivation;

    if (this.loadAmount > 0) {
      cardActivation = {
        action: CardActions[CardActions.activateWithLoad],
        cardCount: this.cardCount,
        selector: {
          cardIds: [firstCardId, lastCardId],
          seqLow: this.lowSeqNumber,
          seqHigh: this.highSeqNumber,
        },
        params: {
          amount: this.loadAmount,
          currency: "EUR"
        }
      }
    }
    else {
      cardActivation = {
        action: CardActions[CardActions.activate],
        cardCount: this.cardCount,
        selector: {
          cardIds: [firstCardId, lastCardId],
          seqLow: this.lowSeqNumber,
          seqHigh: this.highSeqNumber,
        }
      }
    }
    return cardActivation;
  }


  // if (this.loadAmount > 0) {
  //    return cardActivation = {
  //     action: CardActions[CardActions.activateWithLoad],
  //     cardCount: this.cardCount,
  //     selector: {
  //       cardIds: [firstCardId, lastCardId],
  //       seqLow: this.lowSeqNumber,
  //       seqHigh: this.highSeqNumber,
  //     },
  //     params: {
  //       amount: this.loadAmount,
  //       currency: "EUR"
  //     }
  //   }
  // }
  // else {

  // }

  countCards(seqHigh: number, seqLow: number): number {
    var _cardCount = seqHigh - seqLow;
    return _cardCount + 1;
  }

  totalAmount() {
    var _totalAmount = this.cardCount * this.loadAmount;
    if (Number.isNaN(_totalAmount)) {
      this.totalLoadAmount = 0;
      return;
    }
    this.totalLoadAmount = _totalAmount;
  }

  cancel() {
    this.ref.close();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LandingComponent } from './components/landing/landing.component';
import { NoResultComponent } from './components/shared/no-result/no-result.component';
import { AuthGuard } from './core/auth.guard';
import { roleGuard } from './core/role-guard.guard';
import { UserRole } from './enums/user-roles';
import { HomeComponent } from './components/home/home.component';
import { ChangePasswordComponent } from './components/user-settings/change-password/change-password.component';
import { AccessDeniedComponent } from './components/shared/access-denied/access-denied.component';
import { LedgerListComponent } from './components/ledger/list/ledger-list.component';


const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '*',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      { path: 'home', component: HomeComponent },
      {
        path: 'cards', children: [
          { path: '', loadChildren: () => import('./components/cards/cards.module').then(m => m.CardsModule) },
          { path: ':id', loadChildren: () => import('./components/cards/details/card-details.module').then(m => m.CardDetailsModule) },
          { path: ':id/summary', loadChildren: () => import('./components/cards/card-summary/card-summary.module').then(m => m.CardSummaryModule) },
          { path: 'group', loadChildren: () => import('./components/cards/card-group/card-group.module').then(m => m.CardGroupModule) },

        ],
        // children: [
        //   { path: 'search', loadChildren: () => import('./components/cards/lookup/search-cards.module').then(m => m.SearchCardsModule) },
        //   { path: 'search/list', loadChildren: () => import('./components/cards/cards.module').then(m => m.CardsModule) },
        //   { path: 'search/list/details', loadChildren: () => import('./components/cards/details/card-details.module').then(m => m.CardDetailsModule) },
        //   { path: 'search/list/card-group', loadChildren: () => import('./components/cards/card-group/card-group.module').then(m => m.CardGroupModule) },
        // ],
        canActivate: [roleGuard], data: { roles: [UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator] }
      },
      {
        path: 'group-activation', children: [
          { path: 'report', loadChildren: () => import('./components/group-card-activation-report/group-card-activation-report.module').then(m => m.GroupCardActivationReportModule) },
        ],
        canActivate: [roleGuard], data: { roles: [UserRole.SupportUserGroupActivation] }
      },
      {
        path: 'transactions', children: [
          { path: 'search', loadChildren: () => import('./components/transactions/lookup/search-transactions.module').then(m => m.SearchTransactionsModule) },
          { path: 'search/list', loadChildren: () => import('./components/transactions/list/transactions.module').then(m => m.TransactionsModule), },
          { path: 'search/list/:searchValue/details/:transactionId', loadChildren: () => import('./components/transactions/details/transaction-details.module').then(m => m.TransactionDetailsModule), },
        ],
        canActivate: [roleGuard], data: { roles: [UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator] }
      },
      {
        path: 'funding-accounts', children: [
          { path: '', loadChildren: () => import('./components/funding-accounts/list/funding-accounts.module').then(m => m.FundingAccountsModule) },
          { path: 'details/:fundingAccountId', loadChildren: () => import('./components/funding-accounts/details/funding-account-details.module').then(m => m.FundingAccountDetailsModule) }
        ],
        canActivate: [roleGuard], data: { roles: [UserRole.FundingAccounts, UserRole.Administator] }
      },
      {
        path: 'programs', children: [
          { path: '', loadChildren: () => import('./components/programs/programs.module').then(m => m.ProgramsModule) },
          { path: 'details/:programId', loadChildren: () => import('./components/programs/details/program-details.module').then(m => m.ProgramDetailsModule), }
        ],
        canActivate: [roleGuard], data: { roles: [UserRole.Programs, UserRole.Administator] }
      },
      {
        path: 'ledgers',
        children: [
          {
            path: '',
            loadComponent: () => import('./components/ledger/list/ledger-list.component').then(x => x.LedgerListComponent)
          },
          {
            path: ':ledgerName',
            loadComponent: () => import('./components/ledger/ledger-transactions/ledger-transactions.component').then(x => x.LedgerTransactionsComponent)
          }
        ],
        canActivate: [roleGuard],
        data: {
          roles: [UserRole.LedgerSystemReporting]
        }
      }

    ],

  },
  { path: 'change-password', pathMatch: 'full', component: ChangePasswordComponent },
  { path: 'landing', pathMatch: 'full', component: LandingComponent },
  { path: 'no-result', component: NoResultComponent, title: 'No Result Found' },
  { path: 'access-denied', component: AccessDeniedComponent, title: 'Access Denied!' },
  { path: 'program-details', loadChildren: () => import('./components/programs/details/program-details.module').then(m => m.ProgramDetailsModule) },
  { path: '**', redirectTo: '' }

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

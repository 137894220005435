import { Injectable, inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { AuthService } from '@auth0/auth0-angular';
import { LocalStorageItem } from '../enums/localStorage-item';


@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    returnAuth: boolean;
    constructor(private router: Router, private auth: AuthService) { 
        console.log('test');
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var t = this.auth.isAuthenticated$.subscribe(isAuthenticated => {
            this.returnAuth = isAuthenticated;

            if(!isAuthenticated && state.url !== '/login'){
                localStorage.setItem(LocalStorageItem.LastUrl, state.url);
                this.router.navigateByUrl('/landing')
                this.returnAuth = false;
            }
            else {
                this.returnAuth = true;
                localStorage.removeItem(LocalStorageItem.LastUrl);
            }
        })
        return this.returnAuth;

    }
}
<div class="grid">
    <div class="col-12">
        <div class="flex justify-content-center align-items-center">
            <p-progressSpinner strokeWidth="2" ariaLabel="loading" />
                <!-- <a>Activating..</a> -->
        </div>
    </div>
    <div class="col-12">
        <div class="flex justify-content-center align-items-center">
            <a>Activating..</a>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpService } from '../http-service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private httpService : HttpService) { }
  
  prepareAccountAction(body :any) {
    return this.httpService.post('/accounts/actions/prepare',body);
  }

  executeAccountAction(actionId : string, body : any) {
    return this.httpService.post(`/accounts/actions/${actionId}/execute`,body);
  }
}

import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AccountsService } from 'src/app/services/cambrist/accounts.service';

@Component({
  selector: 'app-load-funds',
  templateUrl: './load-funds.component.html',
  styleUrls: ['./load-funds.component.scss']
})
export class LoadFundsComponent {

  activating: boolean;
  externalCardId : string;
  loadAmount : number;
  activationForm = this.formBuilder.group({
    externalCardId: ['', Validators.required],
    loadAmount: [''],
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,private service: MessageService, private formBuilder: FormBuilder, private accountService : AccountsService) {
    
  }

  loadFunds() : void {

  }

  cancel() : void {
    
  }
}

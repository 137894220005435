import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss']
})
export class NoResultComponent {
  @Input() targetRoute: string = ''; // Input property to accept the target route
  @Input() resultMessage: string = 'Your search has no results. Try a different search.';
  @Input() enableEditSearch : boolean = true;
  constructor(private router: Router) {}

  onEditSearch() {
    if (this.targetRoute) {
      this.router.navigate([this.targetRoute]);
    } else {
      // Provide a default route if targetRoute is not set
      console.warn('No target route specified. Navigating to default route.');
      this.router.navigate(['default']);
    }
  }
}

export enum UserRole  {
    SupportUser = 'HermesClient_CardSupportUser',
    ClientManager  = 'HermesClient_ClientManager',
    Administator ='HermesClient_SetldPayAdministrator',
    LedgerSystemReporting = "HermesClient_LedgerSystemReporting",
    SupportUserGroupActivation =  "HermesClient_SupportUserGroupActivation",
    Programs = "HermesClient_ProgramSupport",
    FundingAccounts = "HermesClient_FundingAccountSupport",
    SetldpayFinancialAdministrator = "SetldPay_Financial_Administrator"
}


import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'hermes-client';
  constructor(private primengConfig: PrimeNGConfig, private translate: TranslateService) {
    this.translate.addLangs(['it', 'en']);
   }

  ngOnInit() {
    JSON.stringify(localStorage);
    this.primengConfig.ripple = true;
    const browserLang = this.translate.getBrowserLang() ?? 'it';
    const supportedLangs = this.translate.getLangs();

    console.log("supportedLangs: ", supportedLangs)
    console.log("browserLang: ", browserLang)

    this.translate.use(supportedLangs.includes(browserLang) ? browserLang : 'it');

    const userLang = localStorage.getItem('preferredLanguage');
    if (userLang) {
      this.translate.use(userLang);
    }
  }
}

import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { UserRole } from '../enums/user-roles';
import { AuthService } from '@auth0/auth0-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    public jwtHelper: JwtHelperService = new JwtHelperService();
    model: any[] = [];
    decodedToken: any;
    isLoading: boolean = true;
    constructor(public layoutService: LayoutService, private authService: AuthService, private translate: TranslateService) { }

    ngOnInit() {

        this.authService.getAccessTokenSilently().subscribe(token => {
            this.decodedToken = this.jwtHelper.decodeToken(token);
            this.model = [
                {
                    visible: this.hasAccess([UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: this.translate.instant('Home'), icon: 'custom-home-icon', routerLink: ['/home'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: this.translate.instant('Cards'), icon: 'custom-cards-icon', routerLink: ['/cards'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.SupportUserGroupActivation]),
                    label: '',
                    items: [
                        { label: this.translate.instant('Group Activation History'), icon: '', routerLink: ['/group-activation/report'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: this.translate.instant('Transactions'), icon: '', routerLink: ['/transactions/search'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.FundingAccounts, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: this.translate.instant('Funding Accounts'), icon: 'custom-wallet-icon', routerLink: ['/funding-accounts'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.Programs, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: this.translate.instant('Programs'), icon: 'custom-program-icon', routerLink: ['/programs'] }
                    ]
                },
                {
                  visible: this.hasAccess([UserRole.LedgerSystemReporting]),
                  label: '',
                  items: [
                    { label: this.translate.instant('Ledger'), routerLink: ['/ledgers'] }
                  ]
                }
            ];
            this.isLoading = false;
        });

        this.authService.user$.subscribe(userToiken => { });
    }

    hasAccess(accessRole: Array<string>): boolean {
        var hasRole = false;
        var _role = this.decodedToken.UserRoles as Array<string>;
        return accessRole.filter(role => _role.includes(role)).length > 0;

    }
}

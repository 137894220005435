<p-button class="custom-dropdown" (click)="openSettings()">
  <img src="{{ selectedLang?.flag }}" alt="{{ selectedLang?.name }}" class="flag-image">
  {{ selectedLang?.name }}
</p-button>


<p-dialog header="Language and Culture Settings" [(visible)]="settingsVisible" [style]="{ width: '25vw', height: '310px' }" [modal]="true">
  <form (ngSubmit)="applySettings()">
    <div class="grid mt-4">
      <div class="field col-12 md:col-12 mt-0 mb-0 flex justify-content-center align-items-center">
        <label for="languages" class="font-semibold w-6rem">Language</label>
        <p-dropdown
          [options]="languages"
          [(ngModel)]="selectedLang"
          (onChange)="onLanguageChange($event.value)"
          name="lang"
          optionLabel="name"
          placeholder="Select Language"
        >
          <ng-template pTemplate="item" let-language>
            <img src="{{ language.flag }}" alt="{{ language.name }}" class="flag-image">
            {{ language.name }}
          </ng-template>

          <ng-template let-language pTemplate="selectedItem">
            <div class="language-option">
              <img [src]="language?.flag" alt="{{ language?.name }}" class="flag-image" />
              {{ language?.name }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="field col-12 md:col-12 mb-3 flex justify-content-center align-items-center">
        <label for="email" class="font-semibold w-6rem">Date Format</label>
        <p-dropdown
          [options]="formats"
          [(ngModel)]="selectedFormat"
          (onChange)="onFormatChange($event.value)"
          name="format"
          [virtualScroll]="true"
          [virtualScrollItemSize]="30"
          [style]="{ 'max-height': '140px' }"
          scrollHeight="140px"
        >
          <ng-template pTemplate="item" let-format>
            <div class="format-option">
              {{ format.dateFormat }}
            </div>
          </ng-template>

          <ng-template let-format pTemplate="selectedItem">
            <div>
              {{ format?.dateFormat }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="field col-12 md:col-6" style="padding: 5px !important">
        <p-button class="flex align-items-center justify-content-center" label="{{'OK' | translate}}" type="submit"></p-button>
      </div>
      <div class="field col-12 md:col-6" style="padding: 5px !important">
        <p-button class="flex align-items-center justify-content-center" label="{{'Cancel' | translate}}" (click)="settingsVisible = false" severity="secondary"></p-button>
      </div>
    </div>
  </form>
</p-dialog>

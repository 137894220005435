import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordModule } from 'primeng/password';
import {ButtonModule} from 'primeng/button';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule } from '@angular/forms';
import {ProgressBarModule} from 'primeng/progressbar';

@NgModule({
  declarations: [
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    PasswordModule,
    ButtonModule,
    FormsModule,
    ProgressBarModule
  ]
})
export class UserSettingsModule { }

import { DatePipe, formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageItem } from '../enums/localStorage-item';
import { DateFormat, DatePickerFormat } from '../enums/date-formats';
import { LanguageCode, TimeZone } from '../enums/language-codes';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  formats = [
    { locale: LanguageCode.Italian, dateFormat: DateFormat.Italian},
    { locale: LanguageCode.EnglishUS, dateFormat: DateFormat.EnglishUS},
    { locale: LanguageCode.EnglishGB, dateFormat: DateFormat.EnglishGB},
    { locale: LanguageCode.English, dateFormat: DateFormat.English},
  ];

  pickerFormat = [
    { locale: LanguageCode.Italian, dateFormat: DatePickerFormat.Italian},
    { locale: LanguageCode.EnglishUS, dateFormat: DatePickerFormat.EnglishUS},
    { locale: LanguageCode.EnglishGB, dateFormat: DatePickerFormat.EnglishGB},
    { locale: LanguageCode.English, dateFormat: DatePickerFormat.English},
  ];

  constructor(@Inject(LOCALE_ID) private locale: string, private translate: TranslateService) { }

  ensureValidCookie(): void {
    const storedDateFormat = localStorage.getItem(LocalStorageItem.PreferredDateFormat);
    console.log('storedDateFormat ', storedDateFormat);

    try {
      if (storedDateFormat != null) {
        const parsedFormat = JSON.parse(storedDateFormat);
        console.log('parsedFormat ', parsedFormat);

        // Check if the parsed format matches expected structure
        const isValidFormat = parsedFormat?.locale && parsedFormat?.dateFormat;

        if (isValidFormat) {
          // Cookie is valid; no need to update
          return;
        }
        console.log("Cookie is not valid");
      }
      else
      {
        return;
      }

      // If invalid, missing, or outdated, update the cookie
      console.log("If invalid, missing, or outdated, update the cookie");
      this.updateCookie();

    } catch (error) {
      console.warn('Invalid date format cookie, updating with default settings:', error);
      // If parsing fails, update the cookie
      this.updateCookie();
    }
  }

  updateCookie(): void {
    const dateFormat = localStorage.getItem(LocalStorageItem.PreferredDateFormat) || 'it-IT';
    const parsedFormat = JSON.parse(dateFormat);
    const userFormat = this.formats.find(c => c.dateFormat === parsedFormat.dateFormat) ?? { locale: 'it-IT', dateFormat: "dd/MM/yy" };
    console.log('updateCookie dateFormat ', parsedFormat);
    console.log('userFormat?.dateFormat ', userFormat);

    const updatedFormat = {
      locale: userFormat.locale,
      dateFormat: userFormat?.dateFormat || 'dd/MM/yy', // Fallback to Italian default
    };

    localStorage.setItem(LocalStorageItem.PreferredDateFormat, JSON.stringify(updatedFormat));
  }

  getLocaleDateFormat(dateString: string): string {
    const userLang = localStorage.getItem('preferredLanguage') || 'it-IT';
    const dateFormat = localStorage.getItem(LocalStorageItem.PreferredDateFormat);
    if (dateFormat) {
      const parsedFormat = JSON.parse(dateFormat);

      if (parsedFormat.locale != userLang) {
        const date = formatDate(dateString, parsedFormat.dateFormat, parsedFormat.locale[0]);
        return date;
      }
    }

    const getFormat = this.formats.find(format => format.locale === userLang)?.dateFormat ?? 'dd/MM/yy';
    const date = formatDate(dateString, getFormat, userLang);
    return date;
  }

  getLocaleDateTimeFormat(timestamp: string): string {
    const date = new Date(timestamp);

    const locale = localStorage.getItem(LocalStorageItem.PreferredLanguage) || LanguageCode.Italian;
    let dateFormat = localStorage.getItem(LocalStorageItem.PreferredDateFormat) || DateFormat.Italian;

    if (!dateFormat) {
      const defaultFormat = this.formats.find(format => format.locale === locale);
      dateFormat = defaultFormat?.dateFormat || DateFormat.Italian;
    } else {
      try {
        const parsedFormat = JSON.parse(dateFormat);
        dateFormat = parsedFormat?.dateFormat || DateFormat.Italian;
      } catch {
        dateFormat = DateFormat.Italian;
      }
    }

    const languageTimeZoneMap: Record<LanguageCode, TimeZone> = {
      [LanguageCode.Italian]: TimeZone.Italian,
      [LanguageCode.EnglishGB]: TimeZone.EnglishGB,
      [LanguageCode.EnglishUS]: TimeZone.EnglishUS,
      [LanguageCode.English]: TimeZone.EnglishUS, // Default for generic English
    };

    const timezone = languageTimeZoneMap[locale as LanguageCode] || TimeZone.Italian;

    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone,
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedTime = date.toLocaleTimeString(locale, options);

    const day = date.toLocaleDateString(locale, { day: "2-digit", timeZone: timezone });
    const month = date.toLocaleDateString(locale, { month: "2-digit", timeZone: timezone });
    const year = date.toLocaleDateString(locale, { year: "numeric", timeZone: timezone });

    const formattedDate = dateFormat
      .replace("MM", month)
      .replace("dd", day)
      .replace("yyyy", year);

    return `${formattedDate} ${formattedTime}`;
  }


  formatAmount(amount: number, currency: string, includeCurrency = true): string {
    const isNegative = amount < 0;
    const absoluteAmount = Math.abs(amount);

    const formattedAmount = new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    }).format(absoluteAmount);

    const formattedValue = isNegative
      ? `( ${formattedAmount} )`
      : formattedAmount;

    return includeCurrency ? formattedValue + ' ' + currency : formattedValue;
  }

  formatIdByGroup(id: string): string {
    const formattedId = id.replace(/(\S{4})/g, '$1 ');

    return formattedId.trim();
  }

  isLanguageSupported(language: string): boolean {
    const supportedLangs = this.translate.getLangs();
    return supportedLangs.includes(language);
  }

  getDateFormat(language: string): string {
    if (language.startsWith('en')) {
      return DateFormat.EnglishUS;
    } else if (language.startsWith('it')) {
      return DateFormat.Italian
    } else {
      return DateFormat.EnglishGB
    }
  }

  isEnglishDateFormat(dateString: string): boolean {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const month = +parts[0];
      const day = +parts[1];
      const year = +parts[2];
      // Validating mm/dd/yy format
      return month > 0 && month <= 12 && day > 0 && day <= 31 && year > 1000;
    }
    return false;
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { ShareableModule } from './components/shared/shareable.module';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { PasswordModule } from 'primeng/password';
import { UserSettingsModule } from './components/user-settings/user-settings.module';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localeIt, 'it');


@NgModule({
  declarations: [AppComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    PasswordModule,
    AppRoutingModule,
    AppLayoutModule,
    DashboardModule,
    ButtonModule,
    DividerModule,
    ShareableModule,
    UserSettingsModule,
    CommonModule,
    AuthModule.forRoot({
      // domain : "dev-ajhrif8z5sswhwtc.eu.auth0.com",
      // clientId : "Lqif3N6lmMRi9nkNYSgmOG8aPdnFOCrR",
      // authorizationParams: {
      //   audience: "https://hermes-api.com",
      //   redirect_uri: "http://localhost:4200",
      // },
      // httpInterceptor : {
      //   allowedList: [`http://localhost:7021/*`],
      // }
       ...env.auth
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  },
  {
    provide: Window,
    useValue: window,
  },

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export enum DateFormat {
    Italian = "dd/MM/yyyy",
    English = "yyyy-MM-dd",
    EnglishUS = "MM/dd/yyyy",
    EnglishGB = "dd/MM/yyyy"
}

export enum DatePickerFormat {
  Italian = "dd/MM/yy",
  English = "yy-MM-dd",
  EnglishUS = "MM/dd/yy",
  EnglishGB = "dd/MM/yy"
}

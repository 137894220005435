import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { NoResultComponent } from './no-result/no-result.component';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarActionComponent } from './toolbar-action/toolbar-action.component';
import { ToolbarModule } from 'primeng/toolbar';
import { RouterModule } from '@angular/router';
import { DialogResultComponent } from './dialog/dialog-result/dialog-result.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { CalendarModule } from 'primeng/calendar';
@NgModule({
  declarations: [
    SearchComponent,
    NoResultComponent,
    ToolbarActionComponent,
    DialogResultComponent,
    AccessDeniedComponent,
    LanguageSelectorComponent,
    DateRangeFilterComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    MessageModule,
    CardModule,
    DialogModule,
    DividerModule,
    InputTextModule,
    ToolbarModule,
    RouterModule,
    TranslateModule,
    DropdownModule,
    CalendarModule
  ],
  exports: [
    SearchComponent,
    NoResultComponent,
    ToolbarActionComponent,
    DialogResultComponent,
    LanguageSelectorComponent,
    DateRangeFilterComponent
  ]

})
export class ShareableModule { }

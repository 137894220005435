import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivateCardsComponent } from '../../dialog/cards/activate-cards/activate-cards.component';
import { LoadFundsComponent } from '../../dialog/cards/load-funds/load.component';
import { ChangeStatusComponent } from '../../dialog/cards/change-status/change-status.component';
import { CreateGroupComponent } from '../../dialog/cards/create-group/create-group.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupActivationComponent } from '../../dialog/cards/group-activation/group-activation.component';
import { UserService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toolbar-action',
  templateUrl: './toolbar-action.component.html',
  styleUrls: ['./toolbar-action.component.scss']
})
export class ToolbarActionComponent implements OnInit {
  statusVisible: boolean = false;
  activateVisible = false;
  createGroupVisible = false;
  loadAmount: number;
  selectedStatus: string;
  cardId: number;
  breadcrumbs: { label: string }[] = [{label : this.translate.instant('Cards')}];
  canActivateCard = false;

  constructor(public dialogService: DialogService,private router: Router, private route: ActivatedRoute, private userService : UserService, private translate: TranslateService) {

  }

  ngOnInit() {
    this.userService.getPermissions().subscribe((res : any) =>{
      for (let i = 0; i < res.length; i++) {
        if (res[i] === "write:cards") {
          this.canActivateCard = true;
          console.log(this.canActivateCard);
          break;
        }
      }
    })
  }

  showModal(modalType: string) {
    //this.activateVisible = true;
    if (modalType === 'activateCards') {
      const ref = this.dialogService.open(ActivateCardsComponent, {
        header: this.translate.instant('Activate Card'),
        width: '30vw'
      });
    }
    else if (modalType === 'loadFunds') {
      const ref = this.dialogService.open(LoadFundsComponent, {
        header: this.translate.instant('Load Funds'),
        width: '30vw'
      });
    }
    else if (modalType === 'changeStatus') {
      const ref = this.dialogService.open(ChangeStatusComponent, {
        header: this.translate.instant('Change Status'),
        width: '30vw'
      });
    }
    else if(modalType === 'cardGroup') {
      //GroupActivationComponent
      const ref = this.dialogService.open(GroupActivationComponent, {
        header: this.translate.instant('Group Activation'),
        width: '30vw'
      });
    }
    else {
      const ref = this.dialogService.open(CreateGroupComponent, {
        header: this.translate.instant('Create Group'),
        width: '30vw'
      });
    }

  }

  @Input() set _createGroupVisible(value: boolean) {
    this.createGroupVisible = value;
  }

  @Input() set _breadCrumbs(label: string) {
    this.breadcrumbs.push({label : label});
  }


  // @Input() set _statusVisibile(value : boolean) {
  //   this.statusVisible = value;
  // }
  // @Input() set _activateVisible(value : boolean) {
  //   this.activateVisible = value;
  // }

  // get _activateVisible(): any {
  //   console.log('test');
  //   return this.activateVisible;
  // }

  // @Input() set _loadAmount(value : number) {
  //   this.loadAmount = value;
  // }
  // @Input() set _selectedStatus(value : string) {
  //   this.selectedStatus = value;
  // }
  // @Input() set _cardId(value : number) {
  //   this.cardId = value;
  // }


  // onActivationDialogClosed() {
  //   // this._activateVisible = false;
  //   // this.activateVisible = false;
  //   console.log('test');
  // }
}

<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
</head>

<div class="container-fluid">
    <div class="row no-gutter">
        <!-- The content half -->
        <div class="col-md-4 bg-light">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 col-xl-7 mx-auto">
<!--                            <p class="p-0 m-0"><img src="../../../assets/layout/images/AG.png" alt=""></p>-->
                            <h1 class="display-4 text-center">Welcome to SetldHub!</h1>
                            <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="form-group mb-3">
                                    <input id="inputEmail" type="email" formControlName="email" class="form-control"
                                        placeholder="Email address"
                                        class="form-control rounded-pill border-0 shadow-sm px-4"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>

                                <div class="form-group mb-3">
                                    <input id="inputPassword" type="password" formControlName="password"
                                        placeholder="Password"
                                        class="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                    </div>
                                </div>

                                <div class="form-group mb-3" >
                                    <div *ngIf="unAuthenticated">Invalid username or password</div>
                                </div>
                                <div class="custom-control custom-checkbox mb-3">
                                    <input id="customCheck1" type="checkbox" checked class="custom-control-input">
                                    <label for="customCheck1" class="custom-control-label">Remember password</label>
                                </div>
                            </form> -->
                            <button
                            class="btn btn-primary btn-block text-uppercase mt-4 rounded-pill shadow-sm" (click)="redirectSignIn()">Sign in</button>
                        </div>
                    </div>
                </div><!-- End -->
            </div>
        </div><!-- End -->
        <!-- The image half -->
        <div class="col-md-8 d-none d-md-flex bg-image"></div>
    </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getDifferenceDays(startDate: Date, endDate: Date): number {
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();

    return Math.floor((endTime - startTime) / (24 * 3600 * 1000));
  }
}

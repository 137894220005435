import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CardActions } from 'src/app/enums/card-actions';
import { ApiResponse } from 'src/app/models/api-response';
import { SingleCardActivation } from 'src/app/models/cambrist/card-activation';
import { PaymentCardService } from 'src/app/services/cambrist/payment-card.service';

@Component({
  selector: 'app-activate-cards',
  templateUrl: './activate-cards.component.html',
  styleUrls: ['./activate-cards.component.scss']
})
export class ActivateCardsComponent {

  externalCardId: string;
  loadAmount: number;
  activating: boolean;
  apiErrorMessage: string;
  activationForm = this.formBuilder.group({
    externalCardId: ['', Validators.required],
    loadAmount: [''],
  });


  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private card: PaymentCardService, private service: MessageService, private formBuilder: FormBuilder) {

  }

  activateCard(): void {

    this.activating = true;
    this.card.getCardIds(this.cardExternalRefs()).subscribe((response: any) => {
      if (response.succeeded && response.data) {
        var cardId = response.data[0].id;
        var accountId = response.data[0].accountId
        var singleCard = this.singleCardActivationData(cardId, accountId);

        this.card.prepareCardAction(singleCard).subscribe((response: ApiResponse) => {
          if (!response.data && !response.succeeded) {
            var split = response.errorResponse.errorText.split(':');
            // assign the neccassary error details.
            this.apiErrorMessage = split[1].toUpperCase();
            // close the loader spinner.
            this.activating = false;
            return;
          }


          this.card.executeCardAction(response.data.id, '').subscribe((response: any) => {
            this.service.add({ key: 'tst', severity: 'Success', summary: 'Processing', detail: 'Execution ID: ' + response.data.id + '.' + ' Status: ' + response.data.status });
            // set the activating to false to disable / hide the loader
            this.activating = false;
            // close the group activation dialog box.
            this.ref.close();
          });

        }, (error) => {
          this.activating = false;
          this.apiErrorMessage = error;
        });
      }
      else {
        this.apiErrorMessage = response.errorResponse.errorText;
        this.activating = false;
      }
      //this.activating = false;
    });

  }

  cardExternalRefs(): Array<string> {
    var cardExtRefs = new Array<string>();
    cardExtRefs.push(this.externalCardId);
    return cardExtRefs
  }


  singleCardActivationData(cardId: number, accountId: string) {
    let singleCardActivation!: SingleCardActivation;

    if(this.loadAmount > 0) {
       singleCardActivation = {
        action: CardActions[CardActions.activateWithLoad],
        selector: {
          cardId: cardId,
          accountId: accountId
        },
        params: {
          amount: this.loadAmount,
          currency: "EUR"
        }
      }
    }
    else {
      singleCardActivation = {
        action: CardActions[CardActions.activate],
        selector: {
          cardId: cardId,
          accountId: accountId
        }
      }
    }
    return singleCardActivation;
  }

  closedDialog() {
    this.ref.close();
  }
  cancel() {
    this.ref.close();
  }
}

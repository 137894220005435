<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <div
            style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div class="text-center mb-5">
                </div>

                <div  *ngIf="!isPasswordChangeSuccess">
                    <div>
                        <div>
                            <label for="email1" class="block text-900 text-xl font-medium mb-2">New Password</label>
                            <p-password [(ngModel)]="newPassword" id="newPassword"  placeholder="New Password" [toggleMask]="true" styleClass="mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>
    
                            <label for="password1" class="block text-900 font-medium text-xl mb-2">Confirm Password</label>
                            <p-password (keyup)="onKeyDown($event)" [(ngModel)]="confirmPassword" id="confirmPassword" [feedback]="false"  placeholder="Confirm Password" [toggleMask]="true" styleClass="mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>
                            <div *ngIf="!isPasswordMatch">
                                <span>Password does not match</span>
                            </div>
                            <div *ngIf="isPasswordError">
                                <span>New password must not match current or previous password </span>
                            </div>
                        </div> 
                    </div>
                    <div>
                        <button [disabled]="alloweSubmit" pButton pRipple label="Update" class="w-full p-3 text-xl" (click)="changePassword()"></button>
                    </div>
                </div>

                <div *ngIf="isPasswordChangeSuccess">
                    <div class="text-900 text-3xl font-medium mb-3">Password has been changed!</div>
                    <div>
                        <p-progressBar mode="indeterminate"></p-progressBar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
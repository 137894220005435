export enum LanguageCode {
    Italian = 'it-IT',
    EnglishUS = 'en-US',
    EnglishGB = 'en-GB',
    English = 'en'
}

export enum Languages {
    Italian = "Italian",
    EnglishGB = "English - GB",
    EnglishUS = "English - US"
}

export enum TimeZone {
  Italian = "Europe/Rome",
  EnglishGB = "Europe/London",
  EnglishUS = "America/New_York"
}

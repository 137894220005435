import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { AuthService } from '@auth0/auth0-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map } from 'rxjs';
import { Token } from '@angular/compiler';
import { UserRole } from '../enums/user-roles';


@Injectable({ providedIn: 'root' })
export class UserService {
    public jwtHelper: JwtHelperService = new JwtHelperService();
    ste: Observable<string>;
    constructor(private http: HttpClient, private auth: AuthService) {

    }

    hasUserRole(role: string): Observable<boolean> {
        return this.auth.getAccessTokenSilently().pipe(
            map(token => {
                const decoded = this.jwtHelper.decodeToken(token);
                const decodedRoles = decoded.UserRoles as string[];

                return decodedRoles.some(x => x == role);

            })
        );
    }

    hasUserRoles(roles: string[]): Observable<boolean> {
        return this.auth.getAccessTokenSilently().pipe(
            map(token => {
                var hasRole = false;
                var _decodedToken = this.jwtHelper.decodeToken(token);
                var _userRole = _decodedToken.UserRoles[0];
                if(roles && roles.length > 0) {
                    for(let i = 0; i < roles.length; i++) {
                        if(roles[i] == _userRole) {
                            hasRole = true;
                            break;
                        }
                    }
                }
                return hasRole;
            })
        )
    }

    getPermissions(): any {
        return this.auth.getAccessTokenSilently().pipe(
            map(token => {
                var permissions : any;
                var _decodedToken = this.jwtHelper.decodeToken(token);
                permissions = _decodedToken.permissions;
                return permissions;
            })
        )
    }

    

    getUserMetadata(): Observable<object> {
      return this.auth.getAccessTokenSilently().pipe(
        map(token => {
          return this.jwtHelper.decodeToken(token).user_metadata;
        })
      )
    }
}


import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit{
  languages: { name: string; code: string; flag: string; }[];
  selectedLang: { name: string; code: string; flag: string; } | undefined;
  defaultLang: string | undefined;

  constructor(private translate: TranslateService, private sharedService: SharedService) {}

  ngOnInit() {
    this.languages = [
      { name: 'Italian', code: 'it', flag: 'assets/images/flags/Italy.png' },
      { name: 'English', code: 'en', flag: 'assets/images/flags/USA.png' },
    ];

    const userLang = localStorage.getItem('preferredLanguage');
    if (userLang) {
      this.selectedLang = this.languages.find(
        lang => lang.code === userLang);
      this.translate.use(userLang);
    } else {
      this.defaultLang = this.translate.getBrowserLang() || 'it';

      if (this.sharedService.isLanguageSupported(this.defaultLang) === false) {
        this.defaultLang = 'it';
      }

      this.selectedLang = this.languages.find(lang => lang.code === this.defaultLang);

      this.translate.use(this.defaultLang || 'it');
      console.log("Default Language if no user preferred language: ", this.defaultLang);
      console.log("Selected Language: ", this.selectedLang);
    }
  }

  onLanguageChange(language: any) {
    this.translate.use(language);
    localStorage.setItem('preferredLanguage', language.code);
    window.location.reload();
  }
}

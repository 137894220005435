<hr class="solid">
<div class="load-status-container">
    <div class="grid">
        <div class="field col-12 md:col-6">
            <h5>Card ID</h5>
            <input pInputText placeholder="Card ID" style="width: 100%;">
        </div>
        <div class="field col-12 md:col-6">
            <h5>Status</h5>
            <input pInputText placeholder="Status" style="width: 100%;">
        </div>
        <div class="field col-12 md:col-6" style="padding: 5px !important">
            <p-button class="flex align-items-center justify-content-center" label="Save"></p-button>
        </div>
        <div class="field col-12 md:col-6" style="padding: 5px !important">
            <p-button class="flex align-items-center justify-content-center" label="Cancel" severity="secondary"
              ></p-button>
        </div>
    </div>
</div> 
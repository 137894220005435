import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;
    fakeUser! :User | null;
    private isValidLogin! : Boolean;
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    async login(email: string, password: string) : Promise<User | null>   {
        const url: string = 'assets/user-list.json';
        await firstValueFrom(this.http.get<[User]>(url)).then((res) => {
            const result  = res.filter(cred => cred.email == email && cred.password == password)
            if(result.length > 0) {
                this.fakeUser =  result[0];
                localStorage.setItem('user', JSON.stringify(this.fakeUser));
                this.userSubject.next(this.fakeUser);
            }
        });
        return this.fakeUser;

    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.fakeUser = null;
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }
}

import { DatePipe } from '@angular/common';
import { ParseError } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateService } from 'src/app/services/date-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { LocalStorageItem } from '../../../enums/localStorage-item';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  providers: [DatePipe]
})
export class DateRangeFilterComponent implements OnInit{
  startDate!: Date;
  endDate!: Date;

  dateFormat!: string;

  defaultStartDate!: Date;
  defaultEndDate!: Date;

  showNoDateSelected: boolean;
  showInvalidDate: boolean;


  constructor(public datePipe: DatePipe,
    private sharedService: SharedService,
    private router: Router,
    private dateService: DateService,
    private translateService : TranslateService) { }

  ngOnInit(): void {
    const userLang = localStorage.getItem('preferredLanguage') || 'it-IT';
    const dateFormat = localStorage.getItem(LocalStorageItem.PreferredDateFormat);
    if (dateFormat) {
      const parsedFormat = JSON.parse(dateFormat);
      if (parsedFormat.locale != userLang) {
        const format = this.sharedService.pickerFormat.find(c => c.locale == parsedFormat.locale) ?? { locale: 'it-IT', dateFormat: "dd/MM/yy" };
        this.dateFormat = format.dateFormat.toLowerCase();
      }
    }
    else{
      const format = this.sharedService.pickerFormat.find(c => c.locale == userLang) ?? { locale: 'it-IT', dateFormat: "dd/MM/yy" };
      this.dateFormat = format.dateFormat.toLowerCase();
    }

    this.defaultEndDate = new Date();
    this.defaultStartDate = new Date();
    this.defaultStartDate.setDate(this.defaultStartDate.getDate() - 30); // 31 days ago

    this.startDate = this.defaultStartDate;
    this.endDate = this.defaultEndDate;
  }

  clickSearch(): void {
    if (!this.startDate || !this.endDate) {
      this.showNoDateSelected = true;

      return;
    }

    const parsedStartDate = new Date(this.startDate);
    const parsedEndDate = new Date(this.endDate);

    const difference = this.dateService.getDifferenceDays(parsedStartDate, parsedEndDate);

    if (difference < 0 || difference > 31) {
      this.showInvalidDate = true;

      return;
    }

    const startDateFormat = `${parsedStartDate.getFullYear()}-${parsedStartDate.getMonth() + 1}-${parsedStartDate.getDate()}T00:00:01.100Z`;
    const endDateFormat = `${parsedEndDate.getFullYear()}-${parsedEndDate.getMonth() + 1}-${parsedEndDate.getDate()}T23:59:59.999Z`;

    this.searchTransactions(startDateFormat, endDateFormat);
  }

  searchTransactions(startDate: string, endDate: string): void {
    this.router.navigate(['transactions/search/list'], {
      queryParams: {
        startDate,
        endDate,
      },
      queryParamsHandling: 'merge',
    });
  }
}

<p-toolbar>
  <ng-template pTemplate="left">
    <div class="p-toolbar-group-start">
      <form>
        <div class="date-section">
          <p-calendar styleClass="calendar-section"
                      inputStyleClass="calendar-filter"
                      placeholder="Start Date"
                      [dateFormat]="dateFormat"
                      dataType="date"
                      [(ngModel)]="startDate"
                      [maxDate]="defaultEndDate"
                      name="startDate"
                      [showIcon]="true"
                      inputId="startDateButton"
                      [showOnFocus]="true"
                      [showClear]="true" />
          <p-calendar styleClass="calendar-section"
                      inputStyleClass="calendar-filter"
                      placeholder="End Date"
                      [(ngModel)]="endDate"
                      [maxDate]="defaultEndDate"
                      name="endDate"
                      [showIcon]="true"
                      inputId="endDateButton"
                      [dateFormat]="dateFormat"
                      dataType="date"
                      [showOnFocus]="true"
                      [showClear]="true" />
          <p-button styleClass="f-search" label="{{'Search' | translate}}" (onClick)="clickSearch()"></p-button>
        </div>
      </form>
    </div>
  </ng-template>
</p-toolbar>

<p-dialog header="Invalid Date"
          [modal]="true"
          [(visible)]="showNoDateSelected"
          [style]="{ width: '25rem' }">
  <div class="flex align-items-center gap-3 mb-3">
    <p>There is not date selected</p>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="OK"
              (onClick)="showNoDateSelected = false" />
  </div>
</p-dialog>

<p-dialog header="{{'Invalid Date' | translate}}"
          [modal]="true"
          [(visible)]="showInvalidDate"
          [style]="{ width: '25rem' }">
  <div class="flex align-items-center gap-3 mb-3">
    <h3>{{ 'The Date between from and to must not exceed 31 days' | translate}}</h3>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="{{'Ok' | translate}}"
              (onClick)="showInvalidDate = false" />
  </div>
</p-dialog>

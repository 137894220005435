import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-result',
  templateUrl: './dialog-result.component.html',
  styleUrls: ['./dialog-result.component.scss']
})
export class DialogResultComponent implements OnInit {
  targetRoute: string = '';
  resultMessage: string = '';
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,private router : Router) {
    
  }
  ngOnInit() {
     this.resultMessage = this.config.data.resultMessage;
  }

  closeDialog() : void {
    this.targetRoute = this.config.data.targetRoute;
    if(this.targetRoute) {
      this.router.navigate([this.targetRoute])
    }
    this.ref.close();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input() label = '';
  @Input() disabled: boolean = false;
  @Output() searchClick: EventEmitter<string> = new EventEmitter<string>();
  placeHolder = '';
  loading = [false, false, false, false];
  searchValue: string = '';

  onSearch() {
    this.searchClick.emit(this.searchValue);
  }

  load(index: number) {
    this.loading[index] = true;
    setTimeout(() => this.loading[index] = false, 1000);
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UserSettingsSErvice } from 'src/app/services/user-settings.services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  newPassword: string;
  confirmPassword: string;
  userId: any;
  hasError: boolean;
  isPasswordError = false;
  isPasswordMatch = true;
  alloweSubmit = false;
  isPasswordChangeSuccess = false;
  errorText = "Password does not match";
  constructor(private router: Router, private userSettingsService: UserSettingsSErvice, private auth0service: AuthService, @Inject(DOCUMENT) private doc: Document) {
    auth0service.user$.subscribe(response => {
      this.userId = response?.sub;
    });
  }

  changePassword(): void {
    this.isPasswordError = false;
    let body = {
      newPassword: this.newPassword
    }

    if (this.isPasswordError && !this.hasError) {
      // this.alloweSubmit = true;
    }
    this.userSettingsService.changePassword(this.userId, body).subscribe(response => {
      if (response) {
        if (!response.succeeded) {
          // will more password error text.
          this.isPasswordError = true;
        }
        else {
          this.isPasswordChangeSuccess = true;
          setTimeout(() => {
            this.auth0service.logout({ logoutParams: { returnTo: this.doc.location.origin + '/#/landing' } });
          }, 5000);
        }
      }
    })
  }

  onKeyDown(event: any): void {
    if (event.target.value.length > 0 && this.newPassword.length > 0) {
      if (this.confirmPassword != this.newPassword) {
        this.isPasswordMatch = false;
      } else {
        this.isPasswordMatch = true;
      }
    }
    if (event.target.value.length == 0) {
      this.isPasswordMatch = true;
    }
  }
}

<p-dropdown
  [options]="languages"
  [(ngModel)]="selectedLang"
  optionLabel="name"
  placeholder="Select Language"
  (onChange)="onLanguageChange($event.value)"
  class="custom-dropdown"
>
  <ng-template pTemplate="item" let-language>
    <img src="{{ language.flag }}" alt="{{ language.name }}" class="flag-image">
    {{ language.name }}
  </ng-template>

  <!-- Selected value template -->
  <ng-template let-language pTemplate="selectedItem">
    <div class="language-option">
      <img [src]="language?.flag" alt="{{ language?.name }}" class="flag-image" />
      {{ language?.name }}
    </div>
  </ng-template>
</p-dropdown>

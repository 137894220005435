import { Injectable } from '@angular/core';
import { HttpService } from '../http-service';
import { Observable, catchError, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentCardService {

  constructor(private httpService: HttpService) { }

  getPaymentCardList(body: any): Observable<any> {
    return this.httpService.post(`/Cards`, body);
  }

  getCardDetailsById(id: any): Observable<any> {
    return this.httpService.post(`/Cards/CardDetails`, id);
  }

  getCardAcitivity(id: number, body: any): Observable<any> {
    return this.httpService.post(`/cards/${id}/cardActivity`, body);
  }

  getCardIds(body: any) {
    return this.httpService.post('/cards/findOne', body);
  }

  prepareCardAction(body: any) {
    return this.httpService.post('/cards/actions/prepare', body);
  }

  executeCardAction(actionId: string, body: any) {
    return this.httpService.post(`/cards/actions/${actionId}/execute`, body);
  }

  prepareCardFundTransfer(cardId: number, body: any) {
    return this.httpService.post(`/cards/${cardId}/actions/prepare`, body);
  }

  executeCardFundTransfer(cardId: any, actionId: string, body: any) {
    return this.httpService.post(`/cards/${cardId}/actions/${actionId}/execute`, body);
  }

  getCardActivationLogs(clientNote: string, startDate: string, endDate: string) {
    var params = `clietNote=${clientNote}&startDate=${startDate}&endDate=${endDate}`;
    return this.httpService.get(`/cardActivationLogs?${params}`);
  }

}
